/**
 * @generated SignedSource<<cc25d3911ca5146db5660a41f78d6fbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useBulkAcceptOfferQuantityPicker_orders$data = ReadonlyArray<{
  readonly relayId: string;
  readonly remainingQuantityType: string;
  readonly " $fragmentType": "useBulkAcceptOfferQuantityPicker_orders";
}>;
export type useBulkAcceptOfferQuantityPicker_orders$key = ReadonlyArray<{
  readonly " $data"?: useBulkAcceptOfferQuantityPicker_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useBulkAcceptOfferQuantityPicker_orders">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useBulkAcceptOfferQuantityPicker_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingQuantityType",
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "0b816bf9493fcf7b326132ab970fa57b";

export default node;
