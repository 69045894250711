import React, { Suspense, useCallback, useEffect } from "react"
import {
  UnstyledButton,
  Text,
  CenterAligned,
  classNames,
  Alert,
  Flex,
} from "@opensea/ui-kit"
import { keyBy } from "lodash"
import { graphql, useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Link } from "@/components/common/Link"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { Block } from "@/design-system/Block"
import {
  MOBILE_MODAL_PADDING,
  Modal,
  MODAL_PADDING,
} from "@/design-system/Modal"
import { linkStyles } from "@/design-system/utils"
import { trackBulkAcceptOffersProcessed } from "@/features/bulk-accept-offer/utils/analytics"
import { useTotalItems } from "@/features/shopping-cart/hooks/useTotalItems"
import { useChains } from "@/hooks/useChains"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import {
  BulkAcceptOffersProcessedModalQuery,
  BulkAcceptOffersProcessedModalQuery$variables,
} from "@/lib/graphql/__generated__/BulkAcceptOffersProcessedModalQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { ItemIdToOrderIds } from "../BulkAcceptOffersModalContent"
import { OfferPriceTotals } from "./OfferPriceTotals.react"
import { ProcessedOfferList } from "./ProcessedOfferList.react"

export type BulkAcceptOfferProcessedModalProps = {
  onClose: () => unknown
  itemIdToOrderIds?: ItemIdToOrderIds
  itemIdToCreatorFeeBasisPoints?: Record<string, number | undefined>
} & BulkAcceptOffersProcessedModalQuery$variables

const BulkAcceptOffersProcessedModalBase = ({
  offersToAccept,
  transactionHashes,
  maxQuantityToFill: maxQuantityToFillProp,
  onClose,
  itemIdToOrderIds,
  itemIdToCreatorFeeBasisPoints,
}: BulkAcceptOfferProcessedModalProps) => {
  const {
    blockchain: {
      bulkAcceptOffers: { ordersFilled },
    },
  } = useLazyLoadQuery<BulkAcceptOffersProcessedModalQuery>(
    graphql`
      query BulkAcceptOffersProcessedModalQuery(
        $offersToAccept: [OfferToAcceptInputType!]!
        $transactionHashes: [String!]!
        $maxQuantityToFill: BigIntScalar
      ) {
        blockchain {
          bulkAcceptOffers(
            offersToAccept: $offersToAccept
            maxQuantityToFill: $maxQuantityToFill
          ) {
            ordersFilled(transactionHashes: $transactionHashes) {
              maxQuantityToFill
              successfulOrders {
                itemFilledAmount
                order {
                  relayId
                  item {
                    ... on AssetType {
                      __typename
                      chain {
                        identifier
                      }
                      ...StackedAssetMedia_assets
                    }

                    ... on AssetBundleType {
                      __typename
                      assetQuantities(first: 30) {
                        edges {
                          node {
                            asset {
                              __typename
                              chain {
                                identifier
                              }
                              ...StackedAssetMedia_assets
                            }
                          }
                        }
                      }
                    }
                  }
                  ...useTotalItems_orders
                }
              }
              failedOrders {
                orderData {
                  item {
                    ... on AssetQuantityDataType {
                      __typename
                      asset {
                        __typename
                        chain {
                          identifier
                        }
                        ...StackedAssetMedia_assets
                      }
                    }

                    ... on AssetBundleType {
                      __typename
                      assetQuantities(first: 30) {
                        edges {
                          node {
                            asset {
                              __typename
                              chain {
                                identifier
                              }
                              ...StackedAssetMedia_assets
                            }
                          }
                        }
                      }
                    }
                  }
                  ...useTotalItems_ordersData
                }
              }
              ...OfferPriceTotals_ordersFilled
              ...ProcessedOfferList_ordersFilled
            }
          }
        }
      }
    `,
    {
      offersToAccept,
      transactionHashes,
      maxQuantityToFill: maxQuantityToFillProp,
    },
    { fetchPolicy: "network-only" },
  )

  const { getTransactionUrl, getBlockExplorerName } = useChains()
  const { isOpen: areDetailsShown, toggle: toggleAreDetailsShown } = useIsOpen()
  const t = useTranslate("bulkAcceptOffers")

  const maxQuantityToFill = ordersFilled?.maxQuantityToFill
    ? Number(ordersFilled.maxQuantityToFill)
    : undefined
  const isSubstitutionEnabled = maxQuantityToFill !== undefined

  const offerToOfferToAccept = keyBy(offersToAccept, "order")

  const orderToSuccessfulOrderFilled = keyBy(
    ordersFilled?.successfulOrders,
    orderFilled => orderFilled.order.relayId,
  )

  const partialSuccessfulOrders = ordersFilled?.successfulOrders.filter(
    orderFilled =>
      bn(orderFilled.itemFilledAmount).isLessThan(
        offerToOfferToAccept[orderFilled.order.relayId].itemFillAmount ?? 1,
      ),
  )

  const failedUnits = offersToAccept.reduce((acc, orderToFill) => {
    if (orderToFill.order in orderToSuccessfulOrderFilled) {
      const orderFailedUnits = bn(orderToFill.itemFillAmount ?? 1).minus(
        orderToSuccessfulOrderFilled[orderToFill.order].itemFilledAmount,
      )
      return acc.plus(orderFailedUnits)
    }
    // If not in successful orders then assume entire order failed
    return acc.plus(orderToFill.itemFillAmount ?? 1)
  }, bn(0))

  const successfulUnits =
    ordersFilled?.successfulOrders.reduce((acc, orderFilled) => {
      return acc.plus(orderFilled.itemFilledAmount)
    }, bn(0)) ?? bn(0)

  const isSuccess =
    (!ordersFilled?.failedOrders.length && !partialSuccessfulOrders?.length) ||
    ordersFilled?.successfulOrders.length === maxQuantityToFill
  const isPartialSuccess = Boolean(
    ordersFilled?.successfulOrders.length && !isSuccess,
  )

  const isFailure = !isSuccess && !isPartialSuccess

  const failedItems = useTotalItems({
    ordersDataDataKey: ordersFilled?.failedOrders.map(
      ({ orderData }) => orderData,
    ),
    ordersDataKey: partialSuccessfulOrders?.map(({ order }) => order),
  })
  const failedOrdersCount = failedItems.length
  const failedItemsCount = new Set(failedItems).size

  const getAssets = useCallback(() => {
    if (ordersFilled) {
      const assets = [
        ...ordersFilled.successfulOrders.flatMap(({ order }) =>
          order.item.__typename === "AssetBundleType"
            ? getNodes(order.item.assetQuantities).map(({ asset }) => asset)
            : order.item.__typename === "AssetType"
            ? order.item
            : null,
        ),
        ...ordersFilled.failedOrders.flatMap(({ orderData }) =>
          orderData.item?.__typename === "AssetBundleType"
            ? getNodes(orderData.item.assetQuantities).map(({ asset }) => asset)
            : orderData.item?.__typename === "AssetQuantityDataType"
            ? orderData.item.asset
            : null,
        ),
      ].flatMap(asset => (asset ? [asset] : []))

      return assets
    }

    return undefined
  }, [ordersFilled])

  useEffect(() => {
    const assets = getAssets()

    if (assets) {
      const chain = assets[0].chain.identifier

      trackBulkAcceptOffersProcessed({
        processedState: isSuccess
          ? "success"
          : isPartialSuccess
          ? "partial success"
          : "fail",
        chain,
        numOrders:
          (ordersFilled?.successfulOrders.length ?? 0) +
          (ordersFilled?.failedOrders.length ?? 0),
      })
    }
  }, [getAssets, isPartialSuccess, isSuccess, ordersFilled])

  if (!ordersFilled) {
    return <ModalLoader />
  }

  const assets = getAssets() ?? []

  const chain = assets[0].chain.identifier

  let heading = ""

  if (isSuccess) {
    heading = t(
      "processedModal.modalTitle.saleComplete",
      "Your sale is complete",
    )
  } else if (isPartialSuccess) {
    heading = t(
      "processedModal.modalTitle.partialComplete",
      "Your sale was partially completed",
    )
  } else {
    heading = t("processedModal.modalTitle.failed", "Your sale failed")
  }

  const getFailedItemCount = (): number => {
    // Display count of failed orders if the orders include multiple NFTs
    if (failedItemsCount > 1) {
      return failedOrdersCount
    }

    // Failed orders include multiple units of a semi-fungible
    if (
      partialSuccessfulOrders?.length || // Has a partial successful order (some units in it failed)
      failedUnits.isGreaterThan(1) // Failed to fulfill multiple units
    ) {
      if (isSubstitutionEnabled) {
        // Number of units that failed of the number requested
        return bn(maxQuantityToFill).minus(successfulUnits).toNumber()
      }

      return failedUnits.toNumber()
    }

    return 1
  }

  return (
    <>
      <Modal.Header />
      <Modal.Body
        maxHeight="80vh"
        padding={{
          _: `${MOBILE_MODAL_PADDING}px ${MOBILE_MODAL_PADDING}px 0 ${MOBILE_MODAL_PADDING}px`,
          sm: `${MODAL_PADDING}px ${MODAL_PADDING}px 0 ${MODAL_PADDING}px`,
        }}
      >
        <CenterAligned className="mb-6">
          <StackedAssetMedia assets={assets} />
        </CenterAligned>
        <CenterAligned>
          <Modal.Body.Title className="mb-1 max-w-[330px]">
            {heading}
          </Modal.Body.Title>
        </CenterAligned>
        <CenterAligned
          className={classNames(areDetailsShown ? "mb-6" : "mb-0")}
        >
          <Flex>
            <Text.Body size="small">
              {transactionHashes.length === 1 ? (
                <Link href={getTransactionUrl(chain, transactionHashes[0])}>
                  {t("explorerLink", "View on {{explorerLink}}", {
                    explorerLink: getBlockExplorerName(chain),
                  })}
                </Link>
              ) : (
                <>
                  {t("explorerLink", "View on {{explorerLink}}", {
                    explorerLink: getBlockExplorerName(chain),
                  })}
                  :
                  {transactionHashes.map((transactionHash, index) => (
                    <span key={transactionHash}>
                      <Link
                        href={getTransactionUrl(chain, transactionHash)}
                        style={{ padding: 1 }}
                      >
                        {" "}
                        {index + 1}
                      </Link>
                      {index === transactionHashes.length - 1 ? "" : ","}
                    </span>
                  ))}
                </>
              )}
            </Text.Body>

            {/* Do not allow showing details on full failures when substitution is enabled */}
            {!(isFailure && isSubstitutionEnabled) && (
              <>
                <VerticalSeparator />
                <UnstyledButton onClick={toggleAreDetailsShown}>
                  <FauxLinkText size="small">
                    {areDetailsShown
                      ? t("details.hide", "Hide details")
                      : t("details.show", "Show details")}
                  </FauxLinkText>
                </UnstyledButton>
              </>
            )}
          </Flex>
        </CenterAligned>

        {(isPartialSuccess || isFailure) && (
          <>
            <Alert className={classNames("mt-6", areDetailsShown && "mb-6")}>
              <Alert.Icon color="error" value="error" />
              <Alert.Content>
                <Alert.Title>
                  {t(
                    "processedModal.failedItemCount",
                    {
                      "0": "{{count}} items failed",
                      one: "{{count}} item failed",
                      other: "{{count}} items failed",
                    },
                    {
                      count: getFailedItemCount(),
                    },
                  )}
                </Alert.Title>
                <Alert.Body>
                  {t(
                    "processedModal.failureReason.generic",
                    "Sales can fail due to network issues, gas fee increases, or because you no longer own the item.",
                  )}
                </Alert.Body>
              </Alert.Content>
            </Alert>
          </>
        )}

        {areDetailsShown && (
          <ProcessedOfferList
            offersToAccept={offersToAccept}
            ordersFilled={ordersFilled}
          />
        )}

        <Block marginTop="24px">
          <OfferPriceTotals
            itemIdToCreatorFeeBasisPoints={itemIdToCreatorFeeBasisPoints}
            itemIdToOrderIds={itemIdToOrderIds}
            ordersFilled={ordersFilled}
          />
        </Block>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClose}>
          {t("processedModal.cta.done", "Done")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}

export const BulkAcceptOffersProcessedModal = (
  props: BulkAcceptOfferProcessedModalProps,
) => {
  return (
    <Suspense fallback={<ModalLoader />}>
      <BulkAcceptOffersProcessedModalBase {...props} />
    </Suspense>
  )
}

const VerticalSeparator = styled(Block)`
  margin: 2px 8px;
  border-right: 1px solid
    ${props => props.theme.colors.components.border.level2};
`

const FauxLinkText = styled(Text.Body)`
  ${linkStyles}
`
