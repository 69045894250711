/**
 * @generated SignedSource<<162e31447475da51af733df127eec19f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferList_orders$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"OfferListItem_order">;
  readonly " $fragmentType": "OfferList_orders";
}>;
export type OfferList_orders$key = ReadonlyArray<{
  readonly " $data"?: OfferList_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferList_orders">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OfferList_orders",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferListItem_order"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "732a8b66f720522f5e2f026f18b99825";

export default node;
