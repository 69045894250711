import { ChainIdentifier } from "@/hooks/useChains/types"
import { getTrackingFn } from "@/lib/analytics"

export const trackBulkAcceptOffersProcessed = getTrackingFn<{
  processedState: "success" | "partial success" | "fail"
  chain: ChainIdentifier
  numOrders: number
}>("bulk accept offers processed")

export const trackBulkAcceptOffersFooterButtonClicked = getTrackingFn<{
  chain?: ChainIdentifier
  numItems: number
}>("bulk accept offers footer button clicked")

export const trackBulkAcceptOffersModalButtonClicked = getTrackingFn<{
  chain?: ChainIdentifier
  numItems: number
}>("bulk accept offers modal button clicked")
