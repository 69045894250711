/**
 * @generated SignedSource<<1b82b8a452ed1c1be031a86127fa8ffe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchetypeInputType = {
  assetContractAddress: string;
  chain?: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value" | null;
  tokenId: string;
};
export type OfferListItemBestOffersQuery$variables = {
  asset: ArchetypeInputType;
};
export type OfferListItemBestOffersQuery$data = {
  readonly bestOffers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly relayId: string;
        readonly " $fragmentSpreads": FragmentRefs<"readOrderForItemAndQuantity_order" | "useBulkAcceptOfferQuantityPicker_orders" | "useTotalOfferPrice_orders">;
      } | null;
    } | null>;
  };
};
export type OfferListItemBestOffersQuery = {
  response: OfferListItemBestOffersQuery$data;
  variables: OfferListItemBestOffersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "asset"
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v2 = [
  {
    "kind": "Variable",
    "name": "asset",
    "variableName": "asset"
  },
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "forTaker",
    "value": {}
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "useTotalOfferPrice_orders"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v1/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeeTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeeType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "basisPoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOpenseaFee",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OfferListItemBestOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrderV2TypeConnection",
        "kind": "LinkedField",
        "name": "bestOffers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderV2TypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderV2Type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "useBulkAcceptOfferQuantityPicker_orders"
                  },
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "readOrderForItemAndQuantity_order",
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ServiceFeeText_orders"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OfferListItemBestOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrderV2TypeConnection",
        "kind": "LinkedField",
        "name": "bestOffers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderV2TypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderV2Type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingQuantityType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriceType",
                    "kind": "LinkedField",
                    "name": "perUnitPriceType",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "usd",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unit",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaymentAssetType",
                    "kind": "LinkedField",
                    "name": "payment",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "symbol",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "FeeTypeConnection",
                    "kind": "LinkedField",
                    "name": "makerFees",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": "makerFees(first:10)"
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "FeeTypeConnection",
                    "kind": "LinkedField",
                    "name": "takerFees",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": "takerFees(first:10)"
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9efb0c16f698f08253cc15807ce911f",
    "id": null,
    "metadata": {},
    "name": "OfferListItemBestOffersQuery",
    "operationKind": "query",
    "text": "query OfferListItemBestOffersQuery(\n  $asset: ArchetypeInputType!\n) {\n  bestOffers(asset: $asset, forTaker: {}, first: 10) {\n    edges {\n      node {\n        relayId\n        ...useBulkAcceptOfferQuantityPicker_orders\n        ...readOrderForItemAndQuantity_order\n        ...useTotalOfferPrice_orders\n        id\n      }\n    }\n  }\n}\n\nfragment ServiceFeeText_orders on OrderV2Type {\n  ...readOrderFees_order\n}\n\nfragment TokenPricePayment on PaymentAssetType {\n  symbol\n}\n\nfragment readOrderFees_order on OrderV2Type {\n  makerFees(first: 10) {\n    edges {\n      node {\n        basisPoints\n        isOpenseaFee\n        id\n      }\n    }\n  }\n  takerFees(first: 10) {\n    edges {\n      node {\n        basisPoints\n        isOpenseaFee\n        id\n      }\n    }\n  }\n}\n\nfragment readOrderForItemAndQuantity_order on OrderV2Type {\n  relayId\n  ...useTotalOfferPrice_orders\n  ...ServiceFeeText_orders\n}\n\nfragment useBulkAcceptOfferQuantityPicker_orders on OrderV2Type {\n  relayId\n  remainingQuantityType\n}\n\nfragment useTotalOfferPrice_orders on OrderV2Type {\n  relayId\n  perUnitPriceType {\n    usd\n    unit\n  }\n  payment {\n    symbol\n    ...TokenPricePayment\n    id\n  }\n  ...readOrderFees_order\n}\n"
  }
};
})();

(node as any).hash = "4e32c62d60e4179393c944042e6c7b08";

export default node;
