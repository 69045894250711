/**
 * @generated SignedSource<<e2d61e76748539a681326bf87bd0bfcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTotalOfferPrice_orders$data = ReadonlyArray<{
  readonly payment: {
    readonly symbol: string;
    readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
  };
  readonly perUnitPriceType: {
    readonly unit: string;
    readonly usd: string;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"readOrderFees_order">;
  readonly " $fragmentType": "useTotalOfferPrice_orders";
}>;
export type useTotalOfferPrice_orders$key = ReadonlyArray<{
  readonly " $data"?: useTotalOfferPrice_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTotalOfferPrice_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeeTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeeType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "basisPoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOpenseaFee",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useTotalOfferPrice_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "TokenPricePayment",
          "selections": [
            (v0/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOrderFees_order",
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "FeeTypeConnection",
          "kind": "LinkedField",
          "name": "makerFees",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "makerFees(first:10)"
        },
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "FeeTypeConnection",
          "kind": "LinkedField",
          "name": "takerFees",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": "takerFees(first:10)"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "4eb8d0d9dd5658e5d3803462f45e7bf0";

export default node;
