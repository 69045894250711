import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList"
import { BlockchainActionModalContent } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { CoreMarketplaceActionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionContext.react"
import { CoreMarketplaceActionErrorFallback } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionErrorFallback.react"
import { useTrackServerError } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import {
  BulkAcceptOffersActionModalQuery,
  BulkAcceptOffersActionModalQuery$variables,
} from "@/lib/graphql/__generated__/BulkAcceptOffersActionModalQuery.graphql"
import {
  ItemIdToCreatorFeeBasisPoints,
  ItemIdToOrderIds,
} from "../BulkAcceptOffersModalContent"
import { BulkAcceptOffersProcessedModal } from "../BulkAcceptOffersProcessedModal"

export type BulkAcceptOffersActionModalProps =
  BulkAcceptOffersActionModalQuery$variables & {
    itemIdToOrderIds?: ItemIdToOrderIds
    itemIdToCreatorFeeBasisPoints?: ItemIdToCreatorFeeBasisPoints
    onClose: () => unknown
    onEnd?: () => unknown
  }

const BulkAcceptOfferActionModalBase = ({
  onClose,
  offersToAccept,
  maxQuantityToFill,
  onEnd,
  itemIdToOrderIds,
  itemIdToCreatorFeeBasisPoints,
}: BulkAcceptOffersActionModalProps) => {
  const {
    blockchain: {
      bulkAcceptOffers: { actions },
    },
  } = useLazyLoadQuery<BulkAcceptOffersActionModalQuery>(
    graphql`
      query BulkAcceptOffersActionModalQuery(
        $offersToAccept: [OfferToAcceptInputType!]!
        $maxQuantityToFill: BigIntScalar
      ) {
        blockchain {
          bulkAcceptOffers(
            offersToAccept: $offersToAccept
            maxQuantityToFill: $maxQuantityToFill
          ) {
            actions {
              ...BlockchainActionList_data
            }
          }
        }
      }
    `,
    {
      offersToAccept,
      maxQuantityToFill,
    },
    { fetchPolicy: "network-only" },
  )

  const { onReplace } = useMultiStepFlowContext()

  return (
    <BlockchainActionList
      dataKey={actions}
      onEnd={({ transactions } = {}) => {
        transactions &&
          onReplace(
            <BulkAcceptOffersProcessedModal
              itemIdToCreatorFeeBasisPoints={itemIdToCreatorFeeBasisPoints}
              itemIdToOrderIds={itemIdToOrderIds}
              maxQuantityToFill={maxQuantityToFill}
              offersToAccept={offersToAccept}
              transactionHashes={transactions.map(t => t.transactionHash)}
              onClose={onClose}
            />,
          )
        onEnd?.()
      }}
    />
  )
}

const BulkAcceptOffersActionModalWithSuspense = (
  props: BulkAcceptOffersActionModalProps,
) => {
  const [trackServerError, errorTags] = useTrackServerError()

  return (
    <ErrorBoundary
      fallback={props => {
        return (
          <CoreMarketplaceActionErrorFallback
            errorTags={errorTags}
            trackServerError={trackServerError}
            {...props}
          />
        )
      }}
    >
      <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
        <BulkAcceptOfferActionModalBase {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

export const BulkAcceptOffersActionModal = (
  props: BulkAcceptOffersActionModalProps,
) => {
  return (
    <CoreMarketplaceActionTrackingContextProvider action="BulkOfferAccept">
      <BulkAcceptOffersActionModalWithSuspense {...props} />
    </CoreMarketplaceActionTrackingContextProvider>
  )
}
