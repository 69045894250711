import React from "react"
import { classNames } from "@opensea/ui-kit"
import { Maybe } from "graphql/jsutils/Maybe"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { TradeMultiModal } from "@/components/trade/TradeMultiModal.react"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Button, ButtonProps } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { COMPROMISED_ACCOUNT_ACTION_DISABLED } from "@/features/settings/constants/messages"
import { useTranslate } from "@/hooks/useTranslate"
import {
  MakeOfferSource,
  trackClickMakeOffer,
} from "@/lib/analytics/events/checkoutEvents"
import { MakeAssetOfferButton_asset$key } from "@/lib/graphql/__generated__/MakeAssetOfferButton_asset.graphql"
import { BigNumber } from "@/lib/helpers/numberUtils"
import { AssetOfferModal } from "./OfferModal/AssetOfferModal.react"

type MakeAssetOfferButtonProps = {
  asset: MakeAssetOfferButton_asset$key
  disabled?: boolean
  initialQuantity?: BigNumber
  overrides?: {
    Button?: { props: ButtonProps }
  }
  source: MakeOfferSource
  onOfferCreated?: () => unknown
  onClick?: () => unknown
}

export const MakeAssetOfferButton = ({
  asset: assetDataKey,
  disabled,
  initialQuantity,
  overrides,
  source,
  onOfferCreated,
  onClick,
}: MakeAssetOfferButtonProps) => {
  const t = useTranslate("orders")
  const activeAccount = useActiveAccount()

  const asset = useFragment(
    graphql`
      fragment MakeAssetOfferButton_asset on AssetType {
        relayId
        isBiddingEnabled {
          value
          reason
        }
        chain {
          identifier
        }
        ...AssetOfferModal_asset
      }
    `,
    assetDataKey,
  )

  const getBiddingDisabledReason = (
    serverReason: Maybe<string>,
  ): string | undefined => {
    if (!serverReason) {
      return undefined
    }
    // TODO(i18n): Localize server strings
    switch (serverReason) {
      case "This NFT is in a bad state. Ask the owner to list on OpenSea":
        return t(
          "trade.station.biddingDisabled.offerNotPossible",
          "Offers can’t currently be made on this Solana NFT. This issue is resolved if listed on OpenSea.",
        )
      case "Cannot bid on NFT without owner":
        return t(
          "trade.station.biddingDisabled.noOwner",
          "This SOL NFT doesn’t have an owner, so no offers can be made on it. This often happens when an NFT is burned.",
        )
      default:
        return serverReason
    }
  }

  return (
    <NetworkUnsupportedGate chainIdentifier={asset.chain.identifier}>
      {({ handleIfNotSupported }) => (
        <TradeMultiModal
          focusFirstFocusableElement={false}
          renderMainModal={onClose => (
            <AssetOfferModal
              asset={asset}
              initialQuantity={initialQuantity}
              onClose={onClose}
              onOrderCreated={onOfferCreated}
            />
          )}
          trigger={open => {
            const isCompromised = activeAccount?.isCompromised ?? false
            const disabledReasonCompromised = isCompromised
              ? COMPROMISED_ACCOUNT_ACTION_DISABLED
              : undefined

            const isBiddingDisabled = !asset.isBiddingEnabled.value
            const biddingDisabledReason = getBiddingDisabledReason(
              asset.isBiddingEnabled.reason,
            )

            const tooltipDisabledReason =
              disabledReasonCompromised ?? biddingDisabledReason
            const isTooltipDisabled = !tooltipDisabledReason

            return (
              <Tooltip
                content={tooltipDisabledReason}
                disabled={isTooltipDisabled}
              >
                <Button
                  disabled={isCompromised || isBiddingDisabled || disabled}
                  icon="sell"
                  variant="secondary"
                  onClick={handleIfNotSupported(() => {
                    trackClickMakeOffer({ assetId: asset.relayId, source })
                    open()
                    onClick?.()
                  })}
                  {...overrides?.Button?.props}
                  className={classNames(
                    "w-full",
                    overrides?.Button?.props.className,
                  )}
                >
                  {t("trade.station.makeOffer", "Make offer")}
                </Button>
              </Tooltip>
            )
          }}
        />
      )}
    </NetworkUnsupportedGate>
  )
}
