/**
 * @generated SignedSource<<84eae9b7887c67f1b95b9b515fda1f21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OfferListItem_item$data = {
  readonly __typename: string;
  readonly acceptOfferDisabled?: {
    readonly " $fragmentSpreads": FragmentRefs<"useAcceptOfferDisabledReason_data">;
  } | null;
  readonly assetContract?: {
    readonly address: string;
    readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
  };
  readonly chain?: {
    readonly identifier: ChainIdentifier;
  };
  readonly collection?: {
    readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
  };
  readonly displayName: string;
  readonly isCurrentlyFungible?: boolean;
  readonly relayId: string;
  readonly tokenId?: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "asset_url" | "readOptionalCreatorFees_item" | "useBulkAcceptOfferQuantityPicker_asset" | "useItemFees_item">;
  readonly " $fragmentType": "OfferListItem_item";
};
export type OfferListItem_item$key = {
  readonly " $data"?: OfferListItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferListItem_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferListItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AcceptOfferDisabledType",
          "kind": "LinkedField",
          "name": "acceptOfferDisabled",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useAcceptOfferDisabledReason_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetContractType",
          "kind": "LinkedField",
          "name": "assetContract",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CollectionLink_assetContract"
            }
          ],
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CollectionLink_collection"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurrentlyFungible",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetMedia_asset"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "asset_url",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "assetContract",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            (v3/*: any*/),
            (v2/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useItemFees_item"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useBulkAcceptOfferQuantityPicker_asset"
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOptionalCreatorFees_item",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "collection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCreatorFeesEnforced",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCreatorFeeBasisPoints",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "5838c05ada445082cfc61118cc418f2c";

export default node;
