import React from "react"
import { Text } from "@opensea/ui-kit"
import { keyBy } from "lodash"
import { graphql, useFragment } from "react-relay"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { OrderUsdPrice } from "@/components/assets/price/OrderUsdPrice.react"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { Item } from "@/design-system/Item"
import { List } from "@/design-system/List"
import { readOfferDataToAcceptPrice } from "@/features/bulk-accept-offer/utils/readOfferDataToAcceptPrice"
import { useTotalItems } from "@/features/shopping-cart/hooks/useTotalItems"
import { ItemSideOverflow } from "@/features/shopping-cart/styles"
import { useTranslate } from "@/hooks/useTranslate"
import { BulkAcceptOffersProcessedModalQuery$variables } from "@/lib/graphql/__generated__/BulkAcceptOffersProcessedModalQuery.graphql"
import { ProcessedOfferList_ordersFilled$key } from "@/lib/graphql/__generated__/ProcessedOfferList_ordersFilled.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { getAssetUrl } from "@/lib/helpers/asset"
import { bn } from "@/lib/helpers/numberUtils"
import THEMES from "@/styles/themes"

type Props = {
  ordersFilled: ProcessedOfferList_ordersFilled$key
} & Pick<BulkAcceptOffersProcessedModalQuery$variables, "offersToAccept">

const ASSET_SIZE = 56

export const ProcessedOfferList = ({
  ordersFilled: ordersFilledDataKey,
  offersToAccept,
}: Props) => {
  const orderToOfferToAccept = keyBy(
    offersToAccept,
    orderToFill => orderToFill.order,
  )

  const { successfulOrders, failedOrders, maxQuantityToFill } = useFragment(
    graphql`
      fragment ProcessedOfferList_ordersFilled on BulkAcceptOffersFilledType {
        maxQuantityToFill
        successfulOrders {
          order {
            relayId
            item {
              ... on AssetType {
                __typename
                displayName

                assetContract {
                  ...CollectionLink_assetContract
                }
                collection {
                  ...CollectionLink_collection
                }
                ...AssetMedia_asset
                ...asset_url
              }

              ... on AssetBundleType {
                __typename
                displayName
                assetQuantities(first: 30) {
                  edges {
                    node {
                      asset {
                        displayName
                        ...StackedAssetMedia_assets
                      }
                    }
                  }
                }
              }
            }
            ...useTotalItems_orders
            ...OrderPrice
            ...OrderUsdPrice
          }
          itemFilledAmount
        }

        failedOrders {
          orderData {
            item {
              ... on AssetQuantityDataType {
                __typename

                asset {
                  __typename
                  relayId
                  displayName
                  assetContract {
                    ...CollectionLink_assetContract
                  }
                  collection {
                    ...CollectionLink_collection
                  }
                  ...AssetMedia_asset
                  ...asset_url
                }
              }

              ... on AssetBundleType {
                __typename
                displayName
                relayId
                assetQuantities(first: 30) {
                  edges {
                    node {
                      asset {
                        displayName
                        ...StackedAssetMedia_assets
                      }
                    }
                  }
                }
              }
            }
            ...useTotalItems_ordersData
          }
          itemFillAmount
          ...readOfferDataToAcceptPrice_offerToAccept
        }
      }
    `,
    ordersFilledDataKey,
  )

  const isSubstitutionEnabled = maxQuantityToFill !== null
  const areFailedOrdersShown = !isSubstitutionEnabled

  const { theme } = useTheme()
  const t = useTranslate("bulkAcceptOffers")

  const totalItems = useTotalItems({
    ordersDataKey: successfulOrders.map(({ order }) => order),
    ordersDataDataKey: areFailedOrdersShown
      ? failedOrders.map(({ orderData }) => orderData)
      : undefined,
  })

  return (
    <>
      <Text.Body className="mb-4" size="medium" weight="semibold">
        {t(
          "itemCount",
          {
            "0": "{{count}} items",
            one: "{{count}} item",
            other: "{{count}} items",
          },
          { count: totalItems.length },
        )}
      </Text.Body>
      <List showBorder={false} variant="framed">
        {successfulOrders.map(({ order, itemFilledAmount }) => {
          const item = order.item

          if (item.__typename === "%other") {
            return null
          }

          const requestedQuantity = isSubstitutionEnabled
            ? bn(1)
            : bn(orderToOfferToAccept[order.relayId].itemFillAmount ?? 1)

          const failedUnits = requestedQuantity.minus(itemFilledAmount)

          return (
            <Item
              className="py-2"
              data-testid="bulk-accept-offers-processed-item"
              key={order.relayId}
            >
              {item.__typename === "AssetType" ? (
                <Item.Avatar className="rounded-xl" size={ASSET_SIZE}>
                  <Link href={getAssetUrl(item)} variant="subtle">
                    <AssetMedia asset={item} size={ASSET_SIZE} />
                  </Link>
                </Item.Avatar>
              ) : (
                <Item.Avatar height={66} width={58}>
                  <StackedAssetMedia
                    assets={getNodes(item.assetQuantities).map(
                      ({ asset }) => asset,
                    )}
                    variant="xsmall"
                  />
                </Item.Avatar>
              )}

              <Item.Content>
                {item.__typename === "AssetType" ? (
                  <Link
                    href={getAssetUrl(item)}
                    style={{ width: "100%" }}
                    variant="subtle"
                  >
                    <Block className="w-full">
                      <Item.Title>
                        <Overflow>{item.displayName}</Overflow>
                      </Item.Title>

                      <Item.Description>
                        <CollectionLink
                          assetContract={item.assetContract}
                          collection={item.collection}
                          isSmall
                          linkStyle={{
                            color: THEMES[theme].colors.text.primary,
                          }}
                        />
                      </Item.Description>
                    </Block>
                  </Link>
                ) : (
                  <>
                    <Item.Title>{item.displayName}</Item.Title>

                    <Item.Description>
                      {t(
                        "itemCount",
                        {
                          "0": "{{count}} items",
                          one: "{{count}} item",
                          other: "{{count}} items",
                        },
                        { count: getNodes(item.assetQuantities).length },
                      )}
                    </Item.Description>
                  </>
                )}

                {!bn(requestedQuantity).isEqualTo(1) && (
                  <Text.Body className="text-secondary" size="tiny">
                    <Overflow>
                      {t(
                        "processedModal.itemQuantity",
                        "Quantity: {{quantity}}",
                        { quantity: requestedQuantity.toString() },
                      )}
                    </Overflow>
                  </Text.Body>
                )}
              </Item.Content>
              <Item.Side>
                <Item.Title>
                  <ItemSideOverflow>
                    <Text.Body
                      color={!failedUnits.isZero() ? "error" : undefined}
                      size="medium"
                    >
                      <OrderPrice
                        fontWeight={400}
                        map={price => price.multipliedBy(itemFilledAmount)}
                        order={order}
                        variant="perUnit"
                      />
                    </Text.Body>
                  </ItemSideOverflow>
                </Item.Title>
                {failedUnits.isZero() ? (
                  <Item.Description>
                    <ItemSideOverflow>
                      <OrderUsdPrice
                        className="text-secondary"
                        fontWeight={400}
                        map={price => price.multipliedBy(itemFilledAmount)}
                        order={order}
                        variant="perUnit"
                      />
                    </ItemSideOverflow>
                  </Item.Description>
                ) : (
                  <Text.Body color="red-3" size="small">
                    {t(
                      "failedUnits",
                      {
                        "0": "{{count}} items failed",
                        one: "{{count}} item failed",
                        other: "{{count}} items failed",
                      },
                      {
                        count: failedUnits.toNumber(),
                      },
                    )}
                  </Text.Body>
                )}
              </Item.Side>
            </Item>
          )
        })}

        {areFailedOrdersShown &&
          failedOrders.map(order => {
            const item =
              order.orderData.item?.__typename === "AssetQuantityDataType"
                ? order.orderData.item.asset
                : order.orderData.item

            if (!item || item.__typename === "%other") {
              return null
            }

            const { price: finalPrice, tokenPricePayment } =
              readOfferDataToAcceptPrice(order)

            const itemFillAmount = order.itemFillAmount

            return (
              <Item
                className="py-2"
                data-testid="bulk-accept-offers-processed-item"
                key={item.relayId}
              >
                {item.__typename === "AssetType" ? (
                  <Item.Avatar className="rounded-xl" size={ASSET_SIZE}>
                    <AssetMedia asset={item} size={ASSET_SIZE} />
                  </Item.Avatar>
                ) : (
                  <Item.Avatar height={72} width={58}>
                    <StackedAssetMedia
                      assets={getNodes(item.assetQuantities).map(
                        ({ asset }) => asset,
                      )}
                      variant="xsmall"
                    />
                  </Item.Avatar>
                )}

                <Item.Content>
                  {item.__typename === "AssetType" ? (
                    <Link
                      href={getAssetUrl(item)}
                      style={{ width: "100%" }}
                      variant="subtle"
                    >
                      <Block className="w-full">
                        <Item.Title>
                          <Overflow>{item.displayName}</Overflow>
                        </Item.Title>

                        <Item.Description>
                          <CollectionLink
                            assetContract={item.assetContract}
                            collection={item.collection}
                            isSmall
                            linkStyle={{
                              color: THEMES[theme].colors.text.primary,
                            }}
                          />
                        </Item.Description>
                      </Block>
                    </Link>
                  ) : (
                    <>
                      <Item.Title>{item.displayName}</Item.Title>

                      <Item.Description>
                        {t(
                          "itemCount",
                          {
                            "0": "{{count}} items",
                            one: "{{count}} item",
                            other: "{{count}} items",
                          },
                          { count: getNodes(item.assetQuantities).length },
                        )}
                      </Item.Description>
                    </>
                  )}

                  {!bn(itemFillAmount).isEqualTo(1) && (
                    <Text.Body className="text-secondary" size="tiny">
                      <Overflow>
                        {t(
                          "processedModal.itemQuantity",
                          "Quantity: {{quantity}}",
                          { quantity: itemFillAmount },
                        )}
                      </Overflow>
                    </Text.Body>
                  )}
                </Item.Content>
                <Item.Side>
                  <Text.Body>
                    <ItemSideOverflow>
                      <TokenPrice
                        color={THEMES[theme].colors.darkCoral}
                        fontWeight={400}
                        {...tokenPricePayment}
                        price={finalPrice}
                        symbolVariant="raw"
                      />
                    </ItemSideOverflow>
                  </Text.Body>
                  <Text.Body color="red-3" size="small">
                    {t("failedOrder", "Failed")}
                  </Text.Body>
                </Item.Side>
              </Item>
            )
          })}
      </List>
    </>
  )
}
