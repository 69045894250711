/**
 * @generated SignedSource<<0aec13a364d13702e9f7531055ea0bef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readOrderForItemAndQuantity_order$data = {
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceFeeText_orders" | "useTotalOfferPrice_orders">;
  readonly " $fragmentType": "readOrderForItemAndQuantity_order";
};
export type readOrderForItemAndQuantity_order$key = {
  readonly " $data"?: readOrderForItemAndQuantity_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"readOrderForItemAndQuantity_order">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readOrderForItemAndQuantity_order"
};

(node as any).hash = "9d13d96e90f83e502bac095f0441f817";

export default node;
