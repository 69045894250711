import { graphql } from "relay-runtime"
import { readOrderForItemAndQuantity_order$data } from "@/lib/graphql/__generated__/readOrderForItemAndQuantity_order.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

export const readOrderForItemAndQuantity =
  inlineFragmentize<readOrderForItemAndQuantity_order$data>(
    graphql`
      fragment readOrderForItemAndQuantity_order on OrderV2Type @inline {
        # eslint-disable-next-line relay/unused-fields
        relayId
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...useTotalOfferPrice_orders
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...ServiceFeeText_orders
      }
    `,
    order => order,
  )
