import React, { useCallback, useMemo, useState } from "react"
import { SpaceBetween, Text, Alert } from "@opensea/ui-kit"
import { FormProvider } from "react-hook-form"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { ServiceFeeText } from "@/components/trade/ServiceFeeText"
import { Item } from "@/design-system/Item"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useTotalOfferPrice } from "@/features/bulk-accept-offer/hooks/useTotalOfferPrice"
import { trackBulkAcceptOffersModalButtonClicked } from "@/features/bulk-accept-offer/utils/analytics"
import { readOptionalCreatorFees } from "@/features/orders/components/AcceptOfferModalContent/readOptionalCreatorFees"
import { readOrderFees } from "@/features/orders/components/AcceptOfferModalContent/readOrderFees"
import { useTotalItems } from "@/features/shopping-cart/hooks/useTotalItems"
import {
  ItemSideOverflow,
  ItemSideOverflowStacked,
} from "@/features/shopping-cart/styles"
import { useForm } from "@/hooks/useForm"
import { useTranslate } from "@/hooks/useTranslate"
import { BulkAcceptOffersModalContent_bestOffers$key } from "@/lib/graphql/__generated__/BulkAcceptOffersModalContent_bestOffers.graphql"
import {
  BulkAcceptOffersModalContentQuery,
  BulkAcceptOffersModalContentQuery$variables,
} from "@/lib/graphql/__generated__/BulkAcceptOffersModalContentQuery.graphql"
import { readOrderForItemAndQuantity_order$data } from "@/lib/graphql/__generated__/readOrderForItemAndQuantity_order.graphql"
import { first } from "@/lib/helpers/array"
import {
  basisPointsToPercentage,
  percentageToBasisPoints,
} from "@/lib/helpers/numberUtils"
import { BulkAcceptOffersActionModal } from "../BulkAcceptOffersActionModal"
import { OrderToQuantity } from "../BulkAcceptOffersModal/BulkAcceptOffersModal.react"
import { OfferList } from "../OfferList"

type Props = BulkAcceptOffersModalContentQuery$variables & {
  onClose: () => unknown
  onOffersAccepted: () => unknown
}

export type ItemOrdersAndQuantity = {
  orderToQuantity: OrderToQuantity
  orders: readOrderForItemAndQuantity_order$data[]
}

export type ItemIdToOrderIds = Record<string, string[] | undefined>
export type ItemIdToCreatorFeeBasisPoints = Record<string, number | undefined>

type ItemRelayID = string

export type BulkAcceptOffersFormData = Record<
  ItemRelayID,
  {
    quantity: string
    totalCreatorFeePercentage: string | undefined
  }
>

export const BulkAcceptOffersModalContent = ({
  assetIds,
  bundleIds,
  onClose,
  onOffersAccepted,
}: Props) => {
  const {
    blockchain: {
      bulkAcceptOffers: { bestOffers },
    },
  } = useLazyLoadQuery<BulkAcceptOffersModalContentQuery>(
    graphql`
      query BulkAcceptOffersModalContentQuery(
        $assetIds: [AssetRelayID!]!
        $bundleIds: [AssetBundleRelayID!]!
      ) {
        blockchain {
          bulkAcceptOffers(offersToAccept: []) {
            bestOffers(assets: $assetIds, bundles: $bundleIds, forTaker: {}) {
              ...BulkAcceptOffersModalContent_bestOffers
            }
          }
        }
      }
    `,
    { assetIds, bundleIds },
    { fetchPolicy: "network-only" },
  )

  return (
    <BulkAcceptOffersModalContentBase
      bestOffers={bestOffers}
      onClose={onClose}
      onOffersAccepted={onOffersAccepted}
    />
  )
}

type BaseProps = {
  bestOffers: BulkAcceptOffersModalContent_bestOffers$key
  onClose: () => unknown
  onOffersAccepted: () => unknown
}

const BulkAcceptOffersModalContentBase = ({
  bestOffers: bestOffersDataKey,
  onClose,
  onOffersAccepted,
}: BaseProps) => {
  const t = useTranslate("bulkAcceptOffers")
  const { onReplace } = useMultiStepFlowContext()

  const bestOffers = useFragment(
    graphql`
      fragment BulkAcceptOffersModalContent_bestOffers on BulkAcceptOffersBestBidsType
      @relay(plural: true) {
        bestBid {
          orderType
          relayId
          ...useTotalItems_orders
          ...useTotalOfferPrice_orders
          ...OfferList_orders
          ...readOrderFees_order
          ...ServiceFeeText_orders
        }
        item {
          chain {
            identifier
          }
          relayId
          ... on AssetType {
            acceptOfferDisabled {
              reason
            }
          }
          ...OfferList_items
          ...readOptionalCreatorFees_item
        }
      }
    `,
    bestOffersDataKey,
  )

  // Specifically used for storing the order to quantity and totalPrice of ERC-1155s
  const [itemOrdersAndQuantity, setItemOrdersAndQuantity] = useState<
    Record<string, ItemOrdersAndQuantity | undefined>
  >({})

  const onItemOrdersAndQuantityChange = useCallback(
    (itemId: string, ordersAndQuantity: ItemOrdersAndQuantity) => {
      setItemOrdersAndQuantity(prev => ({
        ...prev,
        [itemId]: ordersAndQuantity,
      }))
    },
    [],
  )

  // It's rare for this to occur, but if any of the items don't have a best bid, "hide" the item entirely.
  // using flatMap for typing purposes as opposed to filter.
  const validBestOffers = bestOffers.flatMap(bestOffer =>
    bestOffer.bestBid
      ? [{ bestBid: bestOffer.bestBid, item: bestOffer.item }]
      : [],
  )

  const validItems = validBestOffers.map(({ item }) => item)

  const formMethods = useForm<BulkAcceptOffersFormData>({
    mode: "onChange",
    defaultValues: validItems.reduce((acc, item) => {
      const { maxBasisPoints } = readOptionalCreatorFees(item)

      return {
        ...acc,
        [item.relayId]: {
          quantity: "1",
          totalCreatorFeePercentage: basisPointsToPercentage(maxBasisPoints),
        } as BulkAcceptOffersFormData[ItemRelayID],
      }
    }, {}),
  })

  const ordersWithoutCustomQuantity = validBestOffers.filter(
    ({ item }) => itemOrdersAndQuantity[item.relayId] === undefined,
  )

  // The overall order to quantity can be calculated by extracting all of the custom order to quantities
  // and then going through every order without a custom quantity and setting them to 1
  const overallOrderToQuantity = useMemo(() => {
    const customOrderToQuantity = Object.values(
      itemOrdersAndQuantity,
    ).reduce<OrderToQuantity>(
      (acc, metadata) => ({
        ...acc,
        ...metadata?.orderToQuantity,
      }),
      {},
    )

    const orderToQuantityWithoutCustomQuantity =
      ordersWithoutCustomQuantity.reduce<OrderToQuantity>(
        (acc, { bestBid }) => {
          return {
            ...acc,
            [bestBid.relayId]: 1,
          }
        },
        {},
      )

    return {
      ...customOrderToQuantity,
      ...orderToQuantityWithoutCustomQuantity,
    }
  }, [itemOrdersAndQuantity, ordersWithoutCustomQuantity])

  // Gathers all of the orders as well as the respective items that correlate to each order
  const overallOrders = [
    ...ordersWithoutCustomQuantity.map(({ bestBid }) => bestBid),
    ...Object.values(itemOrdersAndQuantity).flatMap(ordersAndQuantity =>
      ordersAndQuantity ? ordersAndQuantity.orders : [],
    ),
  ]

  const itemIdToOrderIds = {
    ...ordersWithoutCustomQuantity.reduce<ItemIdToOrderIds>(
      (acc, { bestBid, item }) => ({
        ...acc,
        [item.relayId]: (acc[item.relayId] ?? []).concat(bestBid.relayId),
      }),
      {},
    ),
    ...Object.entries(itemOrdersAndQuantity).reduce<ItemIdToOrderIds>(
      (acc, [itemId, ordersAndQuantity]) => ({
        ...acc,
        [itemId]: (acc[itemId] ?? []).concat(
          ordersAndQuantity?.orders.map(order => order.relayId) ?? [],
        ),
      }),
      {},
    ),
  }

  const numItems = useTotalItems({
    ordersDataKey: validBestOffers.map(({ bestBid }) => bestBid),
  }).length

  const itemIdToCreatorFeeBasisPoints = Object.fromEntries(
    Object.entries(formMethods.getValues()).map(
      ([itemId, { totalCreatorFeePercentage }]) => [
        itemId,
        percentageToBasisPoints(totalCreatorFeePercentage ?? 0),
      ],
    ),
  )

  const {
    totalPricePerSymbol,
    totalUsdPriceAfterFees,
    averageCreatorEarningPercentage,
  } = useTotalOfferPrice({
    orders: overallOrders,
    itemIdToOrderIds,
    orderToQuantity: overallOrderToQuantity,
    itemIdToCreatorFeeBasisPoints,
  })

  const showNotEnoughOffersWarning = validBestOffers.length < bestOffers.length

  const onSubmit = formMethods.handleSubmit(formData => {
    const chain = first(validBestOffers)?.item.chain.identifier
    trackBulkAcceptOffersModalButtonClicked({ chain, numItems })
    const offersToAccept = validBestOffers.flatMap(({ bestBid, item }) => {
      const { quantity: itemFillAmount, totalCreatorFeePercentage } =
        formData[item.relayId]

      const { maxBasisPoints } = readOptionalCreatorFees(item)
      const { getCorrectedOptionalFeeBasisPointsForAcceptingOrder } =
        readOrderFees(bestBid)

      const optionalCreatorFeeBasisPoints = totalCreatorFeePercentage
        ? getCorrectedOptionalFeeBasisPointsForAcceptingOrder({
            suppliedBasisPoints: percentageToBasisPoints(
              totalCreatorFeePercentage,
            ),
            maxBasisPoints,
          })?.toString()
        : undefined

      const customItemOrderToQuantity = itemOrdersAndQuantity[item.relayId]

      if (customItemOrderToQuantity) {
        return Object.entries(customItemOrderToQuantity.orderToQuantity).map(
          ([order, quantity]) => {
            return {
              order,
              itemFillAmount: quantity.toString(),
              // Technically not needed to be passed in if the order isn't a criteria order, but setting it for now as it won't hurt
              criteriaAsset: item.relayId,
              optionalCreatorFeeBasisPoints,
            }
          },
        )
      }

      return {
        itemFillAmount,
        optionalCreatorFeeBasisPoints,
        criteriaAsset: bestBid.orderType === "CRITERIA" ? item.relayId : null,
        order: bestBid.relayId,
      }
    })

    onReplace(
      <BulkAcceptOffersActionModal
        itemIdToCreatorFeeBasisPoints={itemIdToCreatorFeeBasisPoints}
        itemIdToOrderIds={itemIdToOrderIds}
        offersToAccept={offersToAccept}
        onClose={onClose}
        onEnd={onOffersAccepted}
      />,
    )
  })

  const hasDisabledItems = validItems.some(
    item => item.acceptOfferDisabled?.reason,
  )

  return (
    <Modal.Form>
      {/* @ts-expect-error: Need custom FormProvider to support inputRef like custom useForm hook */}
      <FormProvider {...formMethods}>
        <Modal.Header>
          <Modal.Header.Title>
            {t(
              "modal.title",
              {
                0: "Accept {{count}} offers",
                one: "Accept {{count}} offer",
                other: "Accept {{count}} offers",
              },
              { count: validBestOffers.length },
            )}
          </Modal.Header.Title>
        </Modal.Header>
        <Modal.Body maxHeight="600px">
          {showNotEnoughOffersWarning && (
            <Alert className="mb-6">
              <Alert.Icon color="warning" value="error" />
              <Alert.Content>
                <Alert.Title>
                  {t(
                    "modal.notEnoughOffers.title",
                    {
                      0: "Only {{count}} items can be sold",
                      one: "Only {{count}} item can be sold",
                      other: "Only {{count}} items can be sold",
                    },
                    { count: validBestOffers.length },
                  )}
                </Alert.Title>

                <Alert.Body>
                  {t(
                    "modal.notEnoughOffers.body",
                    "There are not enough offers to sell all selected items.",
                  )}
                </Alert.Body>
              </Alert.Content>
            </Alert>
          )}
          <SpaceBetween className="mb-3">
            <Text.Body weight="semibold">
              {t(
                "modal.itemCount",
                {
                  "0": "{{count}} items",
                  one: "{{count}} item",
                  other: "{{count}} items",
                },
                { count: numItems },
              )}
            </Text.Body>
            <Text.Body weight="semibold">
              {t("modal.offerValue", "Offer value")}
            </Text.Body>
          </SpaceBetween>

          <OfferList
            items={validBestOffers.map(({ item }) => item)}
            orders={validBestOffers.map(({ bestBid }) => bestBid)}
            onItemOrdersAndQuantityChange={onItemOrdersAndQuantityChange}
          />

          <Item className="mt-4 py-2" variant="unstyled">
            <Item.Content>
              <Item.Title className="block h-full">
                {t("totalOfferValue", "Total offer value")}
              </Item.Title>
            </Item.Content>

            <Item.Side className="max-w-full">
              <Item.Title>
                <ItemSideOverflowStacked>
                  {Object.values(totalPricePerSymbol).map(
                    price =>
                      price && (
                        <Text.Body
                          key={price.tokenPricePayment.symbol}
                          size="medium"
                        >
                          <TokenPrice
                            {...price.tokenPricePayment}
                            fontWeight={400}
                            key={price.tokenPricePayment.symbol}
                            price={price.price}
                            symbolVariant="raw"
                          />
                        </Text.Body>
                      ),
                  )}
                </ItemSideOverflowStacked>
              </Item.Title>
            </Item.Side>
          </Item>

          <SpaceBetween className="mb-2">
            <Text.Body>{t("creatorEarnings", "Creator earnings")}</Text.Body>
            <Text.Body>{averageCreatorEarningPercentage}</Text.Body>
          </SpaceBetween>

          <SpaceBetween className="mb-2">
            <Text.Body>{t("openseaFee", "OpenSea fee")}</Text.Body>
            <ServiceFeeText orders={overallOrders} />
          </SpaceBetween>

          <Item variant="unstyled">
            <Item.Content>
              <Item.Title className="block h-full">
                {t("totalEarnings", "Total earnings")}
              </Item.Title>
            </Item.Content>

            <Item.Side className="max-w-full">
              <Item.Title>
                <ItemSideOverflowStacked>
                  {Object.values(totalPricePerSymbol).map(
                    price =>
                      price && (
                        <Text.Body
                          key={price.tokenPricePayment.symbol}
                          size="medium"
                          weight="semibold"
                        >
                          <TokenPrice
                            {...price.tokenPricePayment}
                            fontWeight={600}
                            key={price.tokenPricePayment.symbol}
                            price={price.priceAfterFees}
                            symbolVariant="raw"
                          />
                        </Text.Body>
                      ),
                  )}
                </ItemSideOverflowStacked>
              </Item.Title>
              <Item.Description>
                <ItemSideOverflow>{totalUsdPriceAfterFees}</ItemSideOverflow>
              </Item.Description>
            </Item.Side>
          </Item>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            disabled={!formMethods.formState.isValid || hasDisabledItems}
            onClick={onSubmit}
          >
            {t("completeSale", "Complete sale")}
          </Modal.Footer.Button>
        </Modal.Footer>
      </FormProvider>
    </Modal.Form>
  )

  return null
}
