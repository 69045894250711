import React, {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useMemo,
} from "react"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { Button, ButtonProps } from "@/design-system/Button"
import { MultiStepModal } from "@/design-system/Modal/MultiStepModal.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsOpen } from "@/hooks/useIsOpen"
import { BulkAcceptOffersModalContent } from "../BulkAcceptOffersModalContent"

export type OrderToQuantity = Record<string, number>

type BulkAcceptOffersButtonContext = {
  chain?: ChainIdentifier
  onSubmit: () => unknown
}

const BulkAcceptOffersButtonContext =
  createContext<BulkAcceptOffersButtonContext>({
    chain: undefined,
    onSubmit: () => null,
  })

export type BulkAcceptOffersModalProps = {
  chain: ChainIdentifier
  children: React.ReactNode
  assetIds: string[]
  bundleIds: string[]
  onOffersAccepted: () => void
}

const BulkAcceptOffersModalBase = ({
  chain,
  children,
  assetIds,
  bundleIds,
  onOffersAccepted,
}: BulkAcceptOffersModalProps) => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen()

  const onSubmit = useCallback(async () => {
    openModal()
  }, [openModal])

  const value = useMemo(() => ({ chain, onSubmit }), [chain, onSubmit])

  return (
    <BulkAcceptOffersButtonContext.Provider value={value}>
      {children}

      <MultiStepModal
        closeOnOverlayClick={false}
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <Suspense fallback={<ModalLoader />}>
          <BulkAcceptOffersModalContent
            assetIds={assetIds}
            bundleIds={bundleIds}
            onClose={closeModal}
            onOffersAccepted={onOffersAccepted}
          />
        </Suspense>
      </MultiStepModal>
    </BulkAcceptOffersButtonContext.Provider>
  )
}

const Trigger = ({
  children,
  disabled,
  onClick,
  ...buttonProps
}: ButtonProps) => {
  const { chain, onSubmit } = useContext(BulkAcceptOffersButtonContext)

  return (
    <NetworkUnsupportedGate chainIdentifier={chain} shouldAuthenticate>
      {({ handleIfNotSupported }) => (
        <Button
          disabled={disabled}
          onClick={(
            event: React.MouseEvent<HTMLButtonElement> &
              React.MouseEvent<HTMLAnchorElement>,
          ) => {
            onClick?.(event)
            handleIfNotSupported(onSubmit)()
          }}
          {...buttonProps}
        >
          {children}
        </Button>
      )}
    </NetworkUnsupportedGate>
  )
}

type TriggerWrapperProps = {
  trigger: (onClick: () => void) => JSX.Element
}
const TriggerWrapper = ({ trigger }: TriggerWrapperProps) => {
  const { chain, onSubmit } = useContext(BulkAcceptOffersButtonContext)

  return (
    <NetworkUnsupportedGate chainIdentifier={chain} shouldAuthenticate>
      {({ handleIfNotSupported }) =>
        trigger(() => {
          handleIfNotSupported(onSubmit)()
        })
      }
    </NetworkUnsupportedGate>
  )
}

export const BulkAcceptOffersModal = Object.assign(BulkAcceptOffersModalBase, {
  Trigger,
  TriggerWrapper,
})
