import React from "react"
import { Separator, SpaceBetween, Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { ServiceFeeText } from "@/components/trade/ServiceFeeText"
import { Item } from "@/design-system/Item"
import { useTotalOfferPrice } from "@/features/bulk-accept-offer/hooks/useTotalOfferPrice"
import {
  ItemSideOverflow,
  ItemSideOverflowStacked,
} from "@/features/shopping-cart/styles"
import { useTranslate } from "@/hooks/useTranslate"
import { OfferPriceTotals_ordersFilled$key } from "@/lib/graphql/__generated__/OfferPriceTotals_ordersFilled.graphql"
import {
  ItemIdToCreatorFeeBasisPoints,
  ItemIdToOrderIds,
} from "../BulkAcceptOffersModalContent"

type Props = {
  ordersFilled: OfferPriceTotals_ordersFilled$key
  itemIdToOrderIds?: ItemIdToOrderIds
  itemIdToCreatorFeeBasisPoints?: ItemIdToCreatorFeeBasisPoints
}

export const OfferPriceTotals = ({
  ordersFilled: ordersFilledDataKey,
  itemIdToOrderIds,
  itemIdToCreatorFeeBasisPoints,
}: Props) => {
  // TODO: Add a separate ItemType field on OrderFilledData so we don't have to manually pass in this mapping
  const { successfulOrders } = useFragment(
    graphql`
      fragment OfferPriceTotals_ordersFilled on BulkAcceptOffersFilledType {
        successfulOrders {
          order {
            relayId
            ...useTotalOfferPrice_orders
            ...ServiceFeeText_orders
          }
          itemFilledAmount
        }
      }
    `,
    ordersFilledDataKey,
  )

  const t = useTranslate("bulkAcceptOffers")

  const orderToQuantity = successfulOrders.reduce(
    (acc, cur) => ({ ...acc, [cur.order.relayId]: cur.itemFilledAmount }),
    {},
  )

  const orders = successfulOrders.map(({ order }) => order)

  const {
    totalPricePerSymbol,
    totalUsdPriceAfterFees,
    averageCreatorEarningPercentage,
  } = useTotalOfferPrice({
    orders,
    itemIdToOrderIds,
    itemIdToCreatorFeeBasisPoints,
    orderToQuantity,
  })

  return (
    <>
      <Item className="mt-4 border-0 pt-2">
        <Item.Content>
          <Item.Title className="block h-full">
            {t("totalPrice", "Total price")}
          </Item.Title>
        </Item.Content>

        <Item.Side className="max-w-full">
          <Item.Title>
            <ItemSideOverflowStacked>
              {Object.values(totalPricePerSymbol).map(
                price =>
                  price && (
                    <Text.Body
                      key={price.tokenPricePayment.symbol}
                      size="medium"
                    >
                      <TokenPrice
                        {...price.tokenPricePayment}
                        fontWeight={400}
                        key={price.tokenPricePayment.symbol}
                        price={price.price}
                        symbolVariant="raw"
                      />
                    </Text.Body>
                  ),
              )}
            </ItemSideOverflowStacked>
          </Item.Title>
        </Item.Side>
      </Item>

      <SpaceBetween className="mb-2">
        <Text.Body>{t("creatorEarnings", "Creator earnings")}</Text.Body>
        <Text.Body>{averageCreatorEarningPercentage}</Text.Body>
      </SpaceBetween>

      <SpaceBetween className="mb-2">
        <Text.Body>{t("openseaFee", "OpenSea fee")}</Text.Body>
        <ServiceFeeText orders={orders} />
      </SpaceBetween>

      <Separator className="my-4" />

      <Item variant="unstyled">
        <Item.Content>
          <Item.Title className="block h-full">
            {t("totalEarnings", "Total earnings")}
          </Item.Title>
        </Item.Content>

        <Item.Side className="max-w-full">
          <Item.Title>
            <ItemSideOverflowStacked>
              {Object.values(totalPricePerSymbol).map(
                price =>
                  price && (
                    <Text.Body
                      key={price.tokenPricePayment.symbol}
                      size="medium"
                      weight="semibold"
                    >
                      <TokenPrice
                        {...price.tokenPricePayment}
                        fontWeight={600}
                        key={price.tokenPricePayment.symbol}
                        price={price.priceAfterFees}
                        symbolVariant="raw"
                      />
                    </Text.Body>
                  ),
              )}
            </ItemSideOverflowStacked>
          </Item.Title>
          <Item.Description>
            <ItemSideOverflow>{totalUsdPriceAfterFees}</ItemSideOverflow>
          </Item.Description>
        </Item.Side>
      </Item>
    </>
  )
}
