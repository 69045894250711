import React, { useCallback, useMemo } from "react"
import { Icon, UnstyledButton, Input, Flex } from "@opensea/ui-kit"
import { Controller, useFormContext } from "react-hook-form"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { FormControl } from "@/design-system/FormControl"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { useBulkAcceptOfferQuantityPicker_asset$key } from "@/lib/graphql/__generated__/useBulkAcceptOfferQuantityPicker_asset.graphql"
import { useBulkAcceptOfferQuantityPicker_orders$key } from "@/lib/graphql/__generated__/useBulkAcceptOfferQuantityPicker_orders.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { OrderToQuantity } from "../components/BulkAcceptOffersModal"
import { BulkAcceptOffersFormData } from "../components/BulkAcceptOffersModalContent"

type useBulkAcceptOfferQuantityPickerProps = {
  orders: useBulkAcceptOfferQuantityPicker_orders$key | null
  asset: useBulkAcceptOfferQuantityPicker_asset$key | null
}

export const useBulkAcceptOfferQuantityPicker = ({
  orders: ordersDataKey,
  asset: assetDataKey,
}: useBulkAcceptOfferQuantityPickerProps) => {
  const t = useTranslate("bulkAcceptOffers")

  const orders = useFragment(
    graphql`
      fragment useBulkAcceptOfferQuantityPicker_orders on OrderV2Type
      @relay(plural: true) {
        relayId
        remainingQuantityType
      }
    `,
    ordersDataKey ?? null,
  )

  const asset = useFragment(
    graphql`
      fragment useBulkAcceptOfferQuantityPicker_asset on AssetType {
        relayId
        ownedQuantity(identity: {})
      }
    `,
    assetDataKey,
  )

  const ordersTotalQuantity = orders?.reduce(
    (acc, offer) => acc.plus(offer.remainingQuantityType),
    bn(0),
  )
  const assetTotalQuantity = asset ? bn(asset.ownedQuantity ?? 0) : undefined

  const maxQuantity =
    ordersTotalQuantity && assetTotalQuantity
      ? BigNumber.min(ordersTotalQuantity, assetTotalQuantity)
      : ordersTotalQuantity ?? assetTotalQuantity ?? bn(0)

  const { control, watch, formState } =
    useFormContext<BulkAcceptOffersFormData>()

  const quantityInputValue = watch(`${asset?.relayId}.quantity`)

  const getQuantityInputValueError = useCallback(
    (value: string): string | undefined => {
      if (value === "") {
        return t(
          "acceptOfferQuantityPicker.quantityInput.errors.required",
          "Quantity is required",
        )
      }

      if (bn(value).isNaN()) {
        return t(
          "acceptOfferQuantityPicker.quantityInput.errors.number",
          "Quantity must be a number",
        )
      }

      if (bn(value).isLessThan(1)) {
        return t(
          "acceptOfferQuantityPicker.quantityInput.errors.minimum",
          "Quantity must be 1 or more",
        )
      }

      if (bn(value).isGreaterThan(maxQuantity)) {
        return t(
          "acceptOfferQuantityPicker.quantityInput.errors.maximum",
          "Max quantity is {{maxQuantity}}",
          {
            maxQuantity: maxQuantity.toString(),
          },
          { forceString: true },
        )
      }

      return undefined
    },
    [maxQuantity, t],
  )

  const quantity = useMemo(
    () => (bn(quantityInputValue).isNaN() ? bn(0) : bn(quantityInputValue)),
    [quantityInputValue],
  )

  const isDecrementQuantityDisabled = quantity.isLessThanOrEqualTo(1)

  const isIncrementQuantityDisabled =
    quantity.isGreaterThanOrEqualTo(maxQuantity)

  const orderToQuantity = useMemo(() => {
    if (!orders) {
      return {}
    }

    const orderToQuantity: OrderToQuantity = {}
    let quantityIncluded = bn(0)
    let offerIndex = 0

    while (
      quantityIncluded.isLessThan(quantity) &&
      offerIndex < orders.length
    ) {
      const offer = orders[offerIndex]

      const quantityFromOfferToInclude = BigNumber.min(
        offer.remainingQuantityType,
        quantity.minus(quantityIncluded),
      )

      orderToQuantity[offer.relayId] = quantityFromOfferToInclude.toNumber()
      quantityIncluded = quantityIncluded.plus(quantityFromOfferToInclude)
      offerIndex++
    }

    return orderToQuantity
  }, [orders, quantity])

  const renderQuantitySelector = useCallback(
    ({
      isFocused,
      setIsFocused,
    }: {
      isFocused: boolean
      setIsFocused: (isFocused: boolean) => unknown
    }) => {
      return (
        <FormControl
          error={getQuantityInputValueError(quantityInputValue)}
          hideLabel
          label={t("acceptOfferQuantityPicker.label", "Quantity")}
        >
          <Controller
            control={control}
            name={`${asset?.relayId}.quantity`}
            render={({ field }) => (
              <Flex className="ml-auto items-center gap-[18px]">
                <UnstyledButton
                  disabled={isDecrementQuantityDisabled}
                  onClick={() => {
                    field.onChange(bn(quantity).minus(1).toString())
                  }}
                >
                  <InteractiveIcon
                    disabled={isDecrementQuantityDisabled}
                    size={20}
                    title={t("modal.cta.decrement", "Decrement quantity")}
                    value="remove"
                  />
                </UnstyledButton>

                <StyledInput
                  $isFocused={isFocused}
                  error={Boolean(
                    formState.errors[`${asset?.relayId}`]?.quantity?.message,
                  )}
                  overrides={{
                    Input: { style: { textAlign: "center" } },
                  }}
                  placeholder="1"
                  type="number"
                  value={quantity.toString()}
                  width="52px"
                  onBlur={() => {
                    setIsFocused(false)
                  }}
                  onChange={field.onChange}
                  onFocus={() => setIsFocused(true)}
                />
                <UnstyledButton
                  disabled={isIncrementQuantityDisabled}
                  onClick={() => {
                    field.onChange(bn(quantity).plus(1).toString())
                  }}
                >
                  <InteractiveIcon
                    disabled={isIncrementQuantityDisabled}
                    size={20}
                    title={t("modal.cta.increment", "Increment quantity")}
                    value="add"
                  />
                </UnstyledButton>
              </Flex>
            )}
            rules={{
              validate: {
                validateWithInput: getQuantityInputValueError,
              },
            }}
          />
        </FormControl>
      )
    },
    [
      asset?.relayId,
      control,
      getQuantityInputValueError,
      isDecrementQuantityDisabled,
      isIncrementQuantityDisabled,
      quantity,
      quantityInputValue,
      t,
      formState.errors,
    ],
  )

  return {
    renderQuantitySelector,
    orderToQuantity,
    quantity,
  }
}

const StyledInput = styled(Input)<{ $isFocused: boolean }>`
  ${props =>
    !props.$isFocused &&
    css`
      border: 0;

      &:hover {
        box-shadow: none;
      }
    `}
`

const InteractiveIcon = styled(Icon)<{ disabled: boolean }>`
  color: ${props => props.theme.colors.text.primary};

  ${interactiveStylesPrimary};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`
