import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { List, ListProps } from "@/design-system/List"
import { useTranslate } from "@/hooks/useTranslate"
import { OfferList_items$key } from "@/lib/graphql/__generated__/OfferList_items.graphql"
import { OfferList_orders$key } from "@/lib/graphql/__generated__/OfferList_orders.graphql"
import { ItemOrdersAndQuantity } from "../BulkAcceptOffersModalContent"
import { OfferListItem } from "./OfferListItem.react"

type OfferListProps = {
  orders: OfferList_orders$key | null
  items: OfferList_items$key | null
  onItemOrdersAndQuantityChange: (
    relayId: string,
    ordersAndQuantity: ItemOrdersAndQuantity,
  ) => void
} & ListProps

export const OfferList = ({
  orders: ordersDataKey,
  items: itemsDataKey,
  onItemOrdersAndQuantityChange,
  ...listProps
}: OfferListProps) => {
  const orders = useFragment(
    graphql`
      fragment OfferList_orders on OrderV2Type @relay(plural: true) {
        ...OfferListItem_order
      }
    `,
    ordersDataKey,
  )

  const items = useFragment(
    graphql`
      fragment OfferList_items on ItemType @relay(plural: true) {
        relayId
        ...OfferListItem_item
      }
    `,
    itemsDataKey,
  )

  const t = useTranslate("bulkPurchase")

  if (!orders || !items) {
    return null
  }

  if (!orders.length) {
    return (
      <Flex className="my-10 h-full justify-center text-center">
        <Text.Body asChild className="text-secondary" size="medium">
          <div>{t("empty", "Add items to get started.")}</div>
        </Text.Body>
      </Flex>
    )
  }

  return (
    <StyledList
      className="min-h-[100px]"
      showBorder={false}
      variant="framed"
      {...listProps}
    >
      {items.map((item, index) => (
        <OfferListItem
          item={item}
          key={item.relayId}
          order={orders[index]}
          onItemOrdersAndQuantityChange={onItemOrdersAndQuantityChange}
        />
      ))}
    </StyledList>
  )
}

// This is so the list can ignore parent padding
const StyledList = styled(List)`
  margin-left: -24px;
  width: calc(100% + 24px);
`
