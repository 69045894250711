/**
 * @generated SignedSource<<58f04932362ae0b90c48c2116447fee3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferList_items$data = ReadonlyArray<{
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"OfferListItem_item">;
  readonly " $fragmentType": "OfferList_items";
}>;
export type OfferList_items$key = ReadonlyArray<{
  readonly " $data"?: OfferList_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferList_items">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OfferList_items",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferListItem_item"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "85dc471fa3d61584b1a2f401508c1207";

export default node;
