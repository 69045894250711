import React, { useEffect, useMemo, useState } from "react"
import {
  Icon,
  useIsLessThanLg,
  UnstyledButton,
  Text,
  VerticalAligned,
  classNames,
  Input,
  Flex,
} from "@opensea/ui-kit"
import { useFormContext } from "react-hook-form"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { ItemQuantityBadge } from "@/components/assets/ItemQuantityBadge.react"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { FormControl } from "@/design-system/FormControl"
import { Item } from "@/design-system/Item"
import { MODAL_PADDING } from "@/design-system/Modal"
import { Tooltip } from "@/design-system/Tooltip"
import { useAcceptOfferDisabledReason } from "@/features/assets/components/AssetPage/components/OffersPanel/useAcceptOfferDisabledReason.react"
import { useBulkAcceptOfferQuantityPicker } from "@/features/bulk-accept-offer/hooks/useBulkAcceptOfferQuantityPicker"
import { useTotalOfferPrice } from "@/features/bulk-accept-offer/hooks/useTotalOfferPrice"
import { readOrderForItemAndQuantity } from "@/features/bulk-accept-offer/utils/readOrderForItemAndQuantity"
import {
  ItemStatusContainer,
  StatusIcon,
} from "@/features/collections/components/CollectionUnreviewedPill.react"
import { readOptionalCreatorFees } from "@/features/orders/components/AcceptOfferModalContent/readOptionalCreatorFees"
import { readOrderFees } from "@/features/orders/components/AcceptOfferModalContent/readOrderFees"
import { ItemSideOverflowStacked } from "@/features/shopping-cart/styles"
import { useHover } from "@/hooks/useHover"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useItemFees } from "@/hooks/useItemFees"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useOptionalCreatorFeeInput } from "@/hooks/useOptionalCreatorFeeInput"
import { useTranslate } from "@/hooks/useTranslate"
import { OfferListItem_item$key } from "@/lib/graphql/__generated__/OfferListItem_item.graphql"
import { OfferListItem_order$key } from "@/lib/graphql/__generated__/OfferListItem_order.graphql"
import { OfferListItemBestOffersQuery } from "@/lib/graphql/__generated__/OfferListItemBestOffersQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { getAssetUrl } from "@/lib/helpers/asset"
import {
  BigNumber,
  bn,
  percentageToBasisPoints,
} from "@/lib/helpers/numberUtils"
import THEMES from "@/styles/themes"
import {
  BulkAcceptOffersFormData,
  ItemOrdersAndQuantity,
} from "../BulkAcceptOffersModalContent"

type OrderItemProps = {
  order: OfferListItem_order$key | null
  item: OfferListItem_item$key | null
  onItemOrdersAndQuantityChange: (
    relayId: string,
    ordersAndQuantity: ItemOrdersAndQuantity,
  ) => void
}

export const OfferListItem = ({
  order: orderDataKey,
  item: itemDataKey,
  onItemOrdersAndQuantityChange,
}: OrderItemProps) => {
  const { theme } = useTheme()
  const isLessThanLg = useIsLessThanLg()
  const [isHovered, hoverRef] = useHover()
  const [isFocused, setIsFocused] = useState(false)
  const { formState, register, watch, setFocus } =
    useFormContext<BulkAcceptOffersFormData>()

  const {
    isOpen: isCreatorEarningsExpanded,
    toggle: toggleIsCreatorEarningsExpanded,
  } = useIsOpen()

  const t = useTranslate("bulkAcceptOffers")

  const order = useFragment(
    graphql`
      fragment OfferListItem_order on OrderV2Type {
        relayId
        ...readOrderFees_order
        ...useTotalOfferPrice_orders
      }
    `,
    orderDataKey,
  )

  const item = useFragment(
    graphql`
      fragment OfferListItem_item on ItemType {
        relayId
        __typename
        displayName

        ... on AssetType {
          acceptOfferDisabled {
            ...useAcceptOfferDisabledReason_data
          }
          assetContract {
            address
            ...CollectionLink_assetContract
          }
          chain {
            identifier
          }
          collection {
            ...CollectionLink_collection
          }
          isCurrentlyFungible
          tokenId
          ...AssetMedia_asset
          ...asset_url
          ...useItemFees_item
          ...useBulkAcceptOfferQuantityPicker_asset
        }
        ...readOptionalCreatorFees_item
      }
    `,
    itemDataKey,
  )

  // Load the first 10 best offers for this item if it's an ERC1155
  // So our quantity picker has those orders to work with
  const [data] = useNoSuspenseLazyLoadQuery<OfferListItemBestOffersQuery>(
    graphql`
      query OfferListItemBestOffersQuery($asset: ArchetypeInputType!) {
        bestOffers(asset: $asset, forTaker: {}, first: 10) {
          edges {
            node {
              relayId
              ...useBulkAcceptOfferQuantityPicker_orders
              ...readOrderForItemAndQuantity_order
              ...useTotalOfferPrice_orders
            }
          }
        }
      }
    `,
    {
      asset: {
        assetContractAddress: item?.assetContract?.address ?? "",
        chain: item?.chain?.identifier,
        tokenId: item?.tokenId ?? "",
      },
    },
    { skip: !item?.isCurrentlyFungible },
  )

  const totalCreatorFeePercentage = watch(
    `${item?.relayId}.totalCreatorFeePercentage`,
  )

  const orders = useMemo(() => getNodes(data?.bestOffers), [data?.bestOffers])

  const { orderToQuantity, quantity, renderQuantitySelector } =
    useBulkAcceptOfferQuantityPicker({
      orders,
      asset: item,
    })

  const ordersInOrderToQuantity = useMemo(() => {
    return orders
      .filter(order => orderToQuantity[order.relayId])
      .map(readOrderForItemAndQuantity)
  }, [orderToQuantity, orders])

  useEffect(() => {
    if (item?.isCurrentlyFungible && ordersInOrderToQuantity.length) {
      onItemOrdersAndQuantityChange(item.relayId, {
        orderToQuantity,
        orders: ordersInOrderToQuantity,
      })
    }
  }, [
    item?.isCurrentlyFungible,
    item?.relayId,
    onItemOrdersAndQuantityChange,
    orderToQuantity,
    ordersInOrderToQuantity,
  ])

  const totalCreatorFeePercentageBn = bn(totalCreatorFeePercentage ?? 0)

  const { creatorFeesText } = useItemFees(
    item?.__typename === "AssetType" ? item : null,
    bn(0),
    percentageToBasisPoints(
      totalCreatorFeePercentageBn.isNaN() || totalCreatorFeePercentageBn.lt(0)
        ? 0
        : BigNumber.min(totalCreatorFeePercentageBn, 100).toString(),
    ),
  )

  const disabledReason = useAcceptOfferDisabledReason(
    item?.acceptOfferDisabled ?? null,
  )

  const disabledStatus = disabledReason ? t("order.disabled", "Disabled") : null

  const optionalCreatorFees = item ? readOptionalCreatorFees(item) : null
  const orderFeesData = order ? readOrderFees(order) : null

  const canApplyOptionalFees =
    orderFeesData?.getCanApplyOptionalFees(
      optionalCreatorFees?.maxBasisPoints ?? 0,
    ) ?? false

  const canEditCreatorFees = order
    ? optionalCreatorFees?.getShowOptionalCreatorFee(canApplyOptionalFees)
    : false

  const creatorFeeInputProps = useOptionalCreatorFeeInput({
    maxBasisPoints: optionalCreatorFees?.maxBasisPoints ?? 0,
    enforcedBasisPoints: orderFeesData?.enforcedBasisPoints,
  })

  // Done in useEffect so that the focus is set after the input is rendered
  useEffect(() => {
    if (isCreatorEarningsExpanded) {
      setFocus(`${item?.relayId}.totalCreatorFeePercentage`)
    }
  }, [isCreatorEarningsExpanded, item?.relayId, setFocus])

  const orderToQuantityForTotalPrice = data?.bestOffers
    ? orderToQuantity
    : { [order?.relayId ?? ""]: quantity.toNumber() }

  const { totalPricePerSymbol } = useTotalOfferPrice({
    orders: data?.bestOffers ? getNodes(data.bestOffers) : order ? [order] : [],
    orderToQuantity: orderToQuantityForTotalPrice,
  })

  if (!order || !item) {
    return null
  }

  const creatorFeesToggle = canEditCreatorFees ? (
    <UnstyledButton
      onClick={() => {
        toggleIsCreatorEarningsExpanded()
      }}
    >
      <Text.Body className="text-secondary" size="tiny">
        <Overflow>{creatorFeesText}</Overflow>
      </Text.Body>
      <Icon
        className="ml-1"
        size={12}
        value={isCreatorEarningsExpanded ? "expand_less" : "expand_more"}
      />
    </UnstyledButton>
  ) : (
    <Text.Body className="text-secondary" size="tiny">
      <Overflow>{creatorFeesText}</Overflow>
    </Text.Body>
  )

  const creatorFeesInput = (
    <Block
      padding={{
        _: `12px ${MODAL_PADDING}px`,
      }}
    >
      <FormControl
        error={
          formState.errors[item.relayId]?.totalCreatorFeePercentage?.message
        }
        inline
        label={
          <Flex>
            {creatorFeeInputProps.label}
            <InfoIcon
              overrides={{
                Button: {
                  style: { marginLeft: "4px" },
                },
                Icon: { size: 14 },
                Tooltip: { interactive: true },
              }}
              tooltipContent={creatorFeeInputProps.tooltipContent}
            />
          </Flex>
        }
      >
        <Input
          data-testid="creator-earnings-input"
          width="100px"
          {...creatorFeeInputProps.inputProps}
          {...register(`${item.relayId}.totalCreatorFeePercentage`, {
            required: true,
            ...creatorFeeInputProps.registerOptions,
          })}
        />
      </FormControl>
    </Block>
  )

  const orderPrices = (
    <Text.Body size="small">
      {Object.keys(totalPricePerSymbol).map(symbol => {
        const price = totalPricePerSymbol[symbol]
        return (
          price && (
            <Flex key={symbol}>
              <TokenPrice
                {...price.tokenPricePayment}
                fontWeight={400}
                price={price.price}
                symbol={price.tokenPricePayment.symbol}
                symbolVariant="raw"
              />
            </Flex>
          )
        )
      })}
    </Text.Body>
  )

  const isInteractive = isLessThanLg || isHovered || isFocused

  return (
    <>
      <StyledItem data-testid="bulk-accept-offers-item" ref={hoverRef}>
        <ItemQuantityBadge
          overrides={{
            QuantityBadge: {
              props: {
                className: classNames("right-[5px] top-[-2px] lg:top-[-10px]"),
              },
            },
          }}
          quantity={quantity}
        >
          <Item.Avatar className="relative rounded-xl" size={72}>
            <Link href={getAssetUrl(item)} variant="subtle">
              <AssetMedia asset={item} size={72} />
            </Link>
          </Item.Avatar>
        </ItemQuantityBadge>

        <Item.Content>
          <VerticalAligned className="w-full">
            <Item.Title>
              <Link href={getAssetUrl(item)} variant="subtle">
                <Overflow>
                  <Text.Body size="small" weight="semibold">
                    {item.displayName}
                  </Text.Body>
                </Overflow>
              </Link>
            </Item.Title>

            {item.collection && (
              <Item.Description>
                <Overflow>
                  <Text.Body size="small">
                    <CollectionLink
                      assetContract={item.assetContract}
                      collection={item.collection}
                      disabled
                      isSmall
                      linkStyle={{
                        color: THEMES[theme].colors.text.primary,
                        opacity: 1,
                      }}
                    />
                  </Text.Body>
                </Overflow>
              </Item.Description>
            )}

            {isLessThanLg && item.isCurrentlyFungible ? orderPrices : null}

            {disabledStatus && disabledReason ? (
              <Tooltip
                content={() => <Block maxWidth="200px">{disabledReason}</Block>}
                interactive
              >
                <ItemStatusContainer variant="error">
                  <StatusIcon
                    $statusVariant="error"
                    className="mr-1"
                    size={16}
                    value="error"
                  />
                  <Text.Body className="text-gray-3" size="tiny">
                    {disabledStatus}
                  </Text.Body>
                </ItemStatusContainer>
              </Tooltip>
            ) : (
              creatorFeesToggle
            )}
          </VerticalAligned>
        </Item.Content>
        <Item.Side className="max-w-full">
          {item.isCurrentlyFungible && isInteractive ? (
            renderQuantitySelector({ isFocused, setIsFocused })
          ) : (
            <ItemSideOverflowStacked>{orderPrices}</ItemSideOverflowStacked>
          )}
        </Item.Side>
      </StyledItem>
      {isCreatorEarningsExpanded ? creatorFeesInput : null}
    </>
  )
}

const StyledItem = styled(Item)`
  padding: 8px;
  margin: 4px 16px;
  width: calc(100% - 8px);

  && {
    border-radius: ${props => props.theme.borderRadius.button};
  }

  &:hover {
    ${props => props.theme.colors.components.background.gray2};
  }
`
