import { useMemo } from "react"
import { cloneDeep } from "lodash"
import { graphql, useFragment } from "react-relay"
import { getTokenPricePayment } from "@/components/assets/price/TokenPrice.react"
import { OrderToQuantity } from "@/components/trade/BulkPurchaseModal"
import { readOrderFees } from "@/features/orders/components/AcceptOfferModalContent/readOrderFees"
import { useTotalOfferPrice_orders$key } from "@/lib/graphql/__generated__/useTotalOfferPrice_orders.graphql"
import {
  BigNumber,
  bn,
  displayFiat,
  multiplyBasisPoints,
  calculatePercentages,
} from "@/lib/helpers/numberUtils"
import {
  ItemIdToCreatorFeeBasisPoints,
  ItemIdToOrderIds,
} from "../components/BulkAcceptOffersModalContent"

type PricesPerSymbol = Record<
  string,
  | {
      prices: BigNumber[]
      usdPrices: BigNumber[]
      tokenPricePayment: ReturnType<typeof getTokenPricePayment>
      creatorFeePrices: BigNumber[]
      creatorFeeUsdPrices: BigNumber[]
      serviceFeePrices: BigNumber[]
      serviceFeeUsdPrices: BigNumber[]
    }
  | undefined
>

type TotalPricePerSymbol = Record<
  string,
  | {
      price: BigNumber
      priceAfterFees: BigNumber
      usdPrice: BigNumber
      tokenPricePayment: ReturnType<typeof getTokenPricePayment>
    }
  | undefined
>

type Props = {
  // Orders and items must be in the same order / map to eachother
  orders: useTotalOfferPrice_orders$key
  orderToQuantity: OrderToQuantity
  itemIdToOrderIds?: ItemIdToOrderIds
  itemIdToCreatorFeeBasisPoints?: ItemIdToCreatorFeeBasisPoints
}

const MAX_BASIS_POINTS = 10_000

export const useTotalOfferPrice = ({
  orders: ordersDataKey,
  orderToQuantity,
  itemIdToCreatorFeeBasisPoints,
  itemIdToOrderIds,
}: Props) => {
  const orders = useFragment(
    graphql`
      fragment useTotalOfferPrice_orders on OrderV2Type @relay(plural: true) {
        relayId
        perUnitPriceType {
          usd
          unit
        }
        payment {
          symbol
          ...TokenPricePayment
        }
        ...readOrderFees_order
      }
    `,
    ordersDataKey,
  )

  const initialPricesPerSymbol: PricesPerSymbol = useMemo(() => ({}), [])

  const pricesPerSymbol = useMemo(() => {
    let tempItemIdToOrderIds = cloneDeep(itemIdToOrderIds)

    return orders.reduce((acc, order) => {
      const { perUnitPriceType, payment, relayId } = order
      const itemId =
        Object.entries(tempItemIdToOrderIds ?? {}).find(
          ([_, orderIds]) => orderIds?.includes(relayId),
        )?.[0] ?? ""

      // We filter out the order id so we don't double count the same order in regards to its fees
      tempItemIdToOrderIds = {
        ...tempItemIdToOrderIds,
        [itemId]: tempItemIdToOrderIds?.[itemId]?.filter(
          orderId => orderId !== relayId,
        ),
      }

      const basePrice = bn(perUnitPriceType.unit)
      const fiatBasePrice = bn(perUnitPriceType.usd)

      const quantity = orderToQuantity[order.relayId] || 0

      const currentPrice = basePrice.multipliedBy(quantity)

      const fiatCurrentPrice = fiatBasePrice.multipliedBy(quantity)

      const { openseaSellerFeeBasisPoints } = readOrderFees(order)

      // Fallback to the optional creator fee basis points if the creator fee basis points is not set
      const actualCreatorFeeBasisPoints = BigNumber.min(
        Number(itemIdToCreatorFeeBasisPoints?.[itemId] || 0),
        MAX_BASIS_POINTS,
      )

      const serviceFeePrice = multiplyBasisPoints(
        currentPrice,
        openseaSellerFeeBasisPoints,
      )

      const serviceFeeUsdPrice = multiplyBasisPoints(
        fiatCurrentPrice,
        openseaSellerFeeBasisPoints,
      )

      const creatorFeePrice = multiplyBasisPoints(
        currentPrice,
        actualCreatorFeeBasisPoints.toNumber(),
      )

      const creatorFeeUsdPrice = multiplyBasisPoints(
        fiatCurrentPrice,
        actualCreatorFeeBasisPoints.toNumber(),
      )

      return {
        ...acc,
        [payment.symbol]: {
          prices: (acc[payment.symbol]?.prices || []).concat(currentPrice),
          usdPrices: (acc[payment.symbol]?.usdPrices || []).concat(
            fiatCurrentPrice,
          ),
          tokenPricePayment: getTokenPricePayment(payment),
          serviceFeePrices: (
            acc[payment.symbol]?.serviceFeePrices || []
          ).concat(serviceFeePrice),
          serviceFeeUsdPrices: (
            acc[payment.symbol]?.serviceFeeUsdPrices || []
          ).concat(serviceFeeUsdPrice),
          creatorFeePrices: (
            acc[payment.symbol]?.creatorFeePrices || []
          ).concat(creatorFeePrice),
          creatorFeeUsdPrices: (
            acc[payment.symbol]?.creatorFeeUsdPrices || []
          ).concat(creatorFeeUsdPrice),
        },
      }
    }, initialPricesPerSymbol)
  }, [
    orders,
    initialPricesPerSymbol,
    itemIdToOrderIds,
    orderToQuantity,
    itemIdToCreatorFeeBasisPoints,
  ])

  const initialTotals: {
    totalPricePerSymbol: TotalPricePerSymbol
    totalUsdPrice: BigNumber
    totalServiceFeeUsdPrice: BigNumber
    totalCreatorFeeUsdPrice: BigNumber
    totalUsdPriceAfterFees: BigNumber
  } = useMemo(
    () => ({
      totalPricePerSymbol: {},
      totalUsdPrice: bn(0),
      totalUsdPriceAfterFees: bn(0),
      totalServiceFeeUsdPrice: bn(0),
      totalCreatorFeeUsdPrice: bn(0),
    }),
    [],
  )

  const totalPrices = useMemo(
    () =>
      Object.entries(pricesPerSymbol).reduce((acc, [symbol, prices]) => {
        if (!prices) {
          return acc
        }

        const {
          prices: unitPrices,
          usdPrices,
          tokenPricePayment,
          serviceFeePrices,
          serviceFeeUsdPrices,
          creatorFeePrices,
          creatorFeeUsdPrices,
        } = prices

        const [
          totalPrice,
          totalUsdPrice,
          totalServiceFeePrice,
          totalServiceFeeUsdPrice,
          totalCreatorFeePrice,
          totalCreatorFeeUsdPrice,
        ] = [
          unitPrices,
          usdPrices,
          serviceFeePrices,
          serviceFeeUsdPrices,
          creatorFeePrices,
          creatorFeeUsdPrices,
        ].map(p => p.reduce((a, b) => a.plus(b), bn(0)))

        return {
          totalPricePerSymbol: {
            ...acc.totalPricePerSymbol,
            [symbol]: {
              price: totalPrice,
              usdPrice: totalUsdPrice,
              priceAfterFees: BigNumber.max(
                0,
                totalPrice
                  .minus(totalServiceFeePrice)
                  .minus(totalCreatorFeePrice),
              ),
              tokenPricePayment,
            },
          },
          totalUsdPrice: acc.totalUsdPrice.plus(totalUsdPrice),
          totalUsdPriceAfterFees: BigNumber.max(
            0,
            acc.totalUsdPriceAfterFees
              .plus(totalUsdPrice)
              .minus(totalServiceFeeUsdPrice)
              .minus(totalCreatorFeeUsdPrice),
          ),
          totalServiceFeeUsdPrice: acc.totalServiceFeeUsdPrice.plus(
            totalServiceFeeUsdPrice,
          ),
          totalCreatorFeeUsdPrice: acc.totalCreatorFeeUsdPrice.plus(
            totalCreatorFeeUsdPrice,
          ),
        }
      }, initialTotals),
    [initialTotals, pricesPerSymbol],
  )

  return {
    totalPricePerSymbol: totalPrices.totalPricePerSymbol,
    totalUsdPrice: displayFiat(totalPrices.totalUsdPrice),
    totalUsdPriceAfterFees: displayFiat(totalPrices.totalUsdPriceAfterFees),
    serviceFeePercentage: `${bn(
      calculatePercentages(
        totalPrices.totalServiceFeeUsdPrice,
        totalPrices.totalUsdPrice,
      ),
    ).decimalPlaces(2)}%`,
    averageCreatorEarningPercentage: `${bn(
      calculatePercentages(
        totalPrices.totalCreatorFeeUsdPrice,
        totalPrices.totalUsdPrice,
      ),
    ).decimalPlaces(2)}%`,
  }
}
