/**
 * @generated SignedSource<<a695bbfb4c365acda50643fb90627830>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferListItem_order$data = {
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"readOrderFees_order" | "useTotalOfferPrice_orders">;
  readonly " $fragmentType": "OfferListItem_order";
};
export type OfferListItem_order$key = {
  readonly " $data"?: OfferListItem_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferListItem_order">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeeTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeeType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "basisPoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOpenseaFee",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferListItem_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOrderFees_order",
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "FeeTypeConnection",
          "kind": "LinkedField",
          "name": "makerFees",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "makerFees(first:10)"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "FeeTypeConnection",
          "kind": "LinkedField",
          "name": "takerFees",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "takerFees(first:10)"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTotalOfferPrice_orders"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "9f0935de9c9325e0540c4d994c93bdae";

export default node;
